
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { StaffListModel } from "@/domain/staff/staff-list/model/StaffListModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import Swal from "sweetalert2";
import { DeleteStaffModel } from "@/domain/staff/delete-staff/model/DeleteStaffModel";

export default defineComponent({
  name: "staff-list",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const loading = ref<boolean>(false);
    const swalNotification = new SwalNotification();
    const staffController = store.state.ControllersModule.staffController;
    const staffList = ref<StaffListModel[]>([]);
    const originalStaffList = ref<StaffListModel[]>([]);

    const isLoading = ref<boolean>(false);
    const isDeletedStaff = ref<boolean>(false);

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const getStaffList = async (page) => {
      isDeletedStaff.value = false;
      staffList.value = [];
      originalStaffList.value = [];

      isLoading.value = true;

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const staffListModel: StaffListModel = {
        staff: {},
        page: page,
        pageSize: pageSize.value,
      };

      staffController
        .staffList(staffListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((staff) => {
              totalItems.value = staff.pagination.total;
              currentPage.value = staff.pagination.page;
              pageCount.value = staff.pagination.pageCount;
              staffList.value.push(staff);
              originalStaffList.value.push(staff);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const deleteStaff = (staffId) => {
      const staffDeleteModel: DeleteStaffModel = {
        id: staffId,
      };

      Swal.fire({
        title: t(SWAL_MESSAGES.ARE_YOU_SURE),
        text: t(SWAL_MESSAGES.SURE_DELETE_STAFF),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          staffController
            .deleteStaff(staffDeleteModel)
            .then((response) => {
              if (response.isSuccess) {
                isDeletedStaff.value = true;
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_STAFF),
                  icon: "success",
                }).then(() => {});
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    watch(isDeletedStaff, (newValue) => {
      if (newValue) {
        getStaffList(1);
      }
    });

    onMounted(() => {
      setCurrentPageTitle(t("ogza.staffList.pageTitle"));
      getStaffList(1);
    });

    return {
      loading,
      staffList,
      isLoading,
      deleteStaff,
      currentPage,
      pageCount,
      pageSize,
      totalItems,
      getStaffList,
    };
  },
});
